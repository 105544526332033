import React from 'react';
import {
  Container, Row, Col
} from 'reactstrap';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import profileImg from '../images/photog.jpg'

export default () => (
  <Layout>
    <Helmet title="About">
      <link rel="canonical" href={`https://wedeography.com/about`} />
      <meta name="og:url" content={`https://wedeography.com/about`} />
    </Helmet>
    <Container>
      <Row>
        <Col xs={12} lg={6}>
          <h1>How it started</h1>
          <p>My name is Shawn We and it began a decade ago when I started uploading videos on YouTube. I eventually gained an interest to capture more action-oriented sports and events.</p>
          <p>With the invention of action cameras like the GoPro, it gave me the freedom to immerse myself right in the heat of the moment while documenting the adrenaline rush.</p>
          <p>Contact me if you want your event/memory relived on repeat.</p>
        </Col>
        <Col xs={12} lg={6} className="h-100 w-100">
          <img
            className="img-fluid rounded mx-auto d-block border border-dark border-thick"
            src={profileImg}
            alt="Profile Picture"
          />
        </Col>
      </Row>
    </Container>

  </Layout>
);
